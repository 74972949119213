<template class="bg-white h-full">
  <div class="bg-white h-full">
    <div class="h-64 bg-gray-800 flex items-center justify-center">
      <h1 class="font-bold text-4xl text-white">
        Politique de confidentialité
      </h1>
    </div>
    <div class="text-center lg:ml-44 lg:mr-44 bg-gray-100 p-4">
      <h1 class="font-bold text-lg text-gray-700 mt-4">
       WADOUNNOU attache une grande importance à la protection des données
personnelles de ses utilisateurs. La présente Politique de Confidentialité vise à
vous informer de la manière dont nous collectons, utilisons et protégeons vos
données personnelles lorsque vous utilisez notre plateforme.
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg font-bold">
        1. Responsable du traitement
      </p>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        Le responsable du traitement des données collectées sur la plateforme WADOUNNOU
est : <br>
WADOUNNOU<br>
Adresse email : contact@wadounnou.com
      </p>
      <p class="text-base text-gray-700 mt-4 text-left text-lg font-bold">
       2. Données collectées
      </p>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        Nous collectons les données personnelles que vous nous fournissez directement
lorsque vous créez un compte, interagissez avec nos services ou nous contactez.
Les types de données que nous collectons incluent, sans s'y limiter :<br>
- Données d'identification : nom, prénom, adresse email, numéro de téléphone<br>
- Informations de connexion : identifiants de compte (nom d'utilisateur, mot de
passe)<br>


      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
        3. Finalités du traitement
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        Vos données personnelles sont collectées et traitées dans les buts suivants :<br>
- Gestion de votre compte utilisateur et accès à la plateforme<br>
- Fourniture des services et contenus disponibles sur WADOUNNOU<br>
- Communication avec les utilisateurs (notifications, assistance, etc.)<br>

- Amélioration continue de la plateforme grâce à l’analyse de l’usage<br>


      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
        4. Fondement juridique du traitement
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
       Les données personnelles des utilisateurs sont traitées conformément à la
législation en vigueur et reposent sur les bases légales suivantes :<br>
- Exécution du contrat : pour la gestion des comptes et la fourniture des services
demandés<br>
- Intérêt légitime : pour améliorer notre plateforme, prévenir les fraudes et
sécuriser les transactions<br>


      
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
        5. Partage des données
      </h1>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
        WADOUNNOU ne vend ni ne loue vos données personnelles à des tiers. Cependant,
nous pouvons être amenés à partager vos informations dans les cas suivants :<br>
- Avec des prestataires de services techniques pour le bon fonctionnement de la
plateforme (hébergement, traitement des paiements, etc.)<br>
- En cas d'obligation légale, pour répondre à des demandes légitimes des
autorités judiciaires ou réglementaires<br>
- Dans le cadre d'une fusion, acquisition ou vente d'actifs, sous réserve du
respect de la confidentialité de vos données
      </p>
       <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
        6. Durée de conservation des données
      </h1>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
       WADOUNNOU conserve vos données personnelles aussi longtemps que nécessaire
pour l’accomplissement des finalités décrites dans cette politique ou pour se
conformer à nos obligations légales.<br>
- Données liées à votre compte : conservées tant que le compte est actif<br>
- Données de transaction : conservées pendant la durée requise par la législation
fiscale et comptable applicable<br>
- Données marketing : conservées jusqu’à ce que vous retiriez votre
consentement
      </p>
       <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
        7. Sécurité des données
      </h1>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
     Nous mettons en place des mesures techniques et organisationnelles appropriées
pour protéger vos données contre les accès non autorisés, la perte, la destruction
ou l'altération. Cependant, malgré tous nos efforts, aucune méthode de
transmission sur Internet ou de stockage électronique n’est totalement sécurisée.
En cas de violation de données, nous vous informerons conformément aux lois
applicables.
      </p>
       <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
       8. Vos droits
      </h1>
       <p class="text-base text-gray-700 mt-9 text-left text-lg">
    Conformément à la législation applicable, vous disposez des droits suivants
concernant vos données personnelles :<br>
- Droit d’accès : obtenir des informations sur les données vous concernant que
nous traitons<br>
- Droit de rectification : demander la correction de données inexactes ou
incomplètes<br>
- Droit de suppression : demander la suppression de vos données personnelles,
sous certaines conditions<br>
- Droit de limitation : demander la limitation du traitement de vos données<br>
- Droit d’opposition : vous opposer au traitement de vos données, notamment à
des fins de prospection<br>
- Droit à la portabilité : recevoir les données que vous nous avez fournies dans
un format structuré, couramment utilisé et lisible par machine
Pour exercer ces droits, vous pouvez nous contacter à l’adresse suivante :
contact@wadounnou.com

      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
       9. Cookies
      </h1>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
    WADOUNNOU utilise des cookies pour améliorer l’expérience utilisateur, analyser
l’utilisation de la plateforme, et vous proposer des contenus adaptés à vos
préférences. Pour plus d'informations sur l'utilisation des cookies, veuillez
consulter notre Politique de Cookies.

      </p>
       <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
     10. Modifications de la Politique de Confidentialité
      </h1>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
  Nous nous réservons le droit de modifier la présente Politique de Confidentialité
à tout moment. Toute modification sera publiée sur cette page et, si nécessaire,
vous en serez informé par email ou par une notification sur la plateforme. Votre
utilisation continue de la plateforme après modification vaut acceptation des
nouvelles conditions.

      </p>
       <h1 class="font-bold text-2xl text-gray-700 mt-2 text-left text-lg">
     11. Contact
      </h1>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
  Pour toute question concernant cette Politique de Confidentialité ou l'utilisation
de vos données personnelles, vous pouvez nous contacter à l'adresse suivante :<br>
Email : support@wadounnou.com 

      </p>
    </div>

    <div class="h-6"></div>
  </div>
</template>
  
<script>
export default {
  name: "App",
  data() {
    return {};
  },
};
</script>
  
<style scoped>
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

input:checked {
  background-color: #22c55e; /* bg-green-500 */
}

input:checked ~ span:last-child {
  --tw-translate-x: 1.75rem; /* translate-x-7 */
}
</style>
  